import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MixpanelPlayer from '../components/MixpanelPlayer'
import { TwitchEmbed } from 'react-twitch-embed'

const IndexPage = () => (
  <Layout>
    {/* <Header siteTitle="DJ" /> */}
    <SEO title="DJ" />
    <TwitchEmbed
      channel="kenvrlim"
      theme="dark"
      width="100%"
    />
    <div style={{
      textAlign: 'center',
      marginTop: 10,
      marginBottom: -10
    }}>
      <h5>Not live? Check out some mixes below</h5>
    </div>
    <MixpanelPlayer
      url="https://www.mixcloud.com/kenvrlim/tribal-melodic-house-live-stream-5-june-2020/"
    />
    <MixpanelPlayer
      url="https://www.mixcloud.com/kenvrlim/hype-may-2020/"
    />
  </Layout>
)

export default IndexPage
