import React from 'react'
import ReactPlayer from 'react-player'

const MixpanelPlayer = ({ url }) => (
  <div style={{
    marginBottom: -50
  }}>
    <ReactPlayer
      url={url}
      width="100%"
      height="100"
    />
  </div>
)

export default MixpanelPlayer
